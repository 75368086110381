<template>
	<div class="container">
		<w-forgot-pwd navigateToAfterForgotPassword="/prihlasit-sa/resetovat-heslo"/>
	</div>
</template>

<script>

export default {
	components: {
		'w-forgot-pwd': () => import('./w-forgot-pwd.vue')
	},
	
	created() {
		if (this.$store.getters['wAuth/isLoggedIn']) this.$router.push('/profil')
	}
}
</script>
